import React, {Component} from 'react';
import {Stage, Layer, Image, Text, Group} from 'react-konva';
import ImageCustom from '../ImageCustom';
import './style.scss';

function UzerbarKonva(props) {
    return (
        <React.Fragment>
            <Stage width={props.width} height={props.height} ref={node => {
                props.setUzerbarRef(node);
            }}>
                <Layer>
                    <ImageCustom src={props.substrate} width="432" height="64" />
                    {
                        !props.hideParts.strip && (
                          <ImageCustom src={props.strip.image} width="256" height="64" />
                        )
                    }
                    {
                        !props.hideParts.badge && (
                          <ImageCustom src={props.badge.image} width="64" height="64" />
                        )
                    }
                    {
                        !props.hideParts.mark && (
                          <ImageCustom src={props.mark.image} width="64" height="64" />
                        )
                    }
                    <ImageCustom src={props.rank.image} width="29" height="32" x="66" y="19" />
                    <Text text={props.nickname} fontFamily="warface_regular" fontSize="13" fill="#fff" x="103" y="26"/>
                    <Text text={props.clan} fontFamily="warface_regular" fontSize="11" fill="#ffe400" x="103" y="13"/>
                    <Text text={props.server} fontFamily="warface_regular" fontSize="11" fill="#fff" x="103" y="40"/>
                    <Text text={props.statLabels} fontFamily="warface_stat" fontSize="7" fill="#fff" x="299" y="13"/>
                    <Text text={props.stats} fontFamily="warface_stat" fontSize="7" fill="#ffe400" x="317" y="13"/>
                </Layer>
            </Stage>
        </React.Fragment>
    );
}

export default UzerbarKonva;