import React, {Component} from 'react';

import InfoForm from './components/InfoForm';
import Achievement from './components/Achievement';
import Rank from './components/Rank';
import UzerbarKanva from './components/UzerbarKanva';
import TabContent from './components/TabContent';
import MainMenu from './components/MainMenu';
import HideAchievement from './components/HideAchievement';

import substrate from './img/substrate.png';
import './App.scss';
import logo from './logo.svg';

import {Container,Tabs, Tab, Row, Col, Button, Form, Navbar, Nav} from 'react-bootstrap';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        parameters: {
          stage: {
            width: 432,
            height: 64
          }
        },
        achievements: {},
        ranks: [],
        servers: {
          1: "Альфа",
          2: "Браво",
          3: "Чарли",
        },
        nickname: "",
        clan: "",
        rank: 0,
        server: 1,
        strip: 0,
        badge: 0,
        mark: 0,
        stats: {
            playTime: 0,
            PVEClass: 'Н/Д',
            PVEWins: 0,
            PVPCLass: 'Н/Д',
            PVPWins: 0,
            KD: '0.00',
        },
        showSearchInput: false,
        searchString: '',
        apiError: {},
        showUpBtn: false,
        isStatLoading: false,
        stripeShowCount: 20,
        markShowCount: 20,
        badgeShowCount: 20,
        activeTabKey: 'info',
        currentPage: 0,
        hideAchievements: {
          mark: false,
          badge: false,
          strip: false
        },
        isLoaded: false
    };
    this.ApiAchievementsUrl = (process.env.NODE_ENV === 'development' ? 'http://warete.lh1.in' : '') + '/achievements_service/';
    this.ApiRanksUrl = '/ranks.json';
    this.ApiStatsUrl = '/wf-api.php?name=';
    this.statLabels = `ОНЛ\nPVE\n\nPVP`;
    this.searchTimer = null;
    this.wfClasses = {
        'Rifleman': 'Штурмовик',
        'Medic': 'Медик',
        'Engineer': 'Инженер',
        'Recon': 'Снайпер',
    };
    this.achievements = {};
  }

  componentDidMount() {
    fetch(this.ApiAchievementsUrl)
        .then(response => response.json())
        .then(json => {
            this.setState({achievements: json, isLoaded: true})
        });
    fetch(this.ApiRanksUrl)
        .then(response => response.json())
        .then(json => {
            this.setState({ranks: json})
        });

    window.onscroll = this.scrollHandler;
    window.onresize = this.scrollHandler;
  }

  smoothJumpUp = (e) => {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
          window.scrollBy(0, (document.documentElement.offsetHeight / 10) * (-1));
          setTimeout(this.smoothJumpUp, 10);
      }
  };

  scrollHandler = () => {
      const scrolled = window.pageYOffset || document.documentElement.scrollTop;
      if (scrolled > 300) {
          this.setState({showUpBtn: true});
      } else {
          this.setState({showUpBtn: false});
      }

      if(window.pageYOffset + window.innerHeight >= document.documentElement.offsetHeight)
      {
          this.setState(({stripeShowCount, markShowCount, badgeShowCount, activeTabKey, achievements}) => {
              switch (activeTabKey) {
                  case 'strip':
                      return {
                          stripeShowCount: Math.min(stripeShowCount + 20, Object.keys(achievements.stripe).length),
                      };
                      break;
                  case 'mark':
                      return {
                          markShowCount: Math.min(markShowCount + 20, Object.keys(achievements.mark).length),
                      };
                      break;
                  case 'badge':
                      return {
                          badgeShowCount: Math.min(badgeShowCount + 20, Object.keys(achievements.badge).length),
                      };
                      break;
              }
          });
      }
  };

  downloadImage(uri, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  setUzerbarRef = (node) => {
      this.uzerbarRef = node;
  };

  onUzerbarDownload = () => {
      this.downloadImage(this.uzerbarRef.toDataURL(), 'uzerbar.png');
  };

  onNicknameChange = (e) => {
    this.setState({nickname: e.target.value});
  };

  onClanChange = (e) => {
    this.setState({clan: e.target.value});
  };

  onServerChange = (e) => {
    this.setState({server: e.target.value});
  };

  onChangeRank = (Id) => {
    this.setState({rank: Id});
  };

  onChangeMark = (Id) => {
    this.setState({mark: Id});
  };

  onChangeBadge = (Id) => {
    this.setState({badge: Id});
  };

  onChangeStrip = (Id) => {
    this.setState({strip: Id});
  };

  onStatLoad = () => {
      if (!this.state.nickname.length)
      {
          this.setState({
              apiError: {
                message: 'Для получения статистики персонажа поле с никнеймом является обязательным'
              }
          });
          return false;
      }
      this.setState({isStatLoading: true, apiError: {}});
      fetch(this.ApiStatsUrl + this.state.nickname + '&server=' + this.state.server)
          .then(resp => {
              try {
                  return resp.json();
              }
              catch (e) {
                  this.setState({
                      apiError: {
                          message: e.message
                      }
                  });
              }
          })
          .then(json => {
              if (typeof json.code !== 'undefined' && json.code === 0)
              {
                  this.setState({
                      apiError: {
                        message: json.message
                      }
                  });
              }
              else
              {
                  const newState = {
                      stats: {
                          playTime: json.playtime_h,
                          PVEClass: this.wfClasses[json.favoritPVE].toUpperCase(),
                          PVEWins: json.pve_wins,
                          PVPCLass: this.wfClasses[json.favoritPVP].toUpperCase(),
                          PVPWins: json.pvp_wins + json.pvp_lost,
                          KD: json.pvp,
                      },
                      nickname: json.nickname,
                      clan: json.clan_name,
                      apiError: {},
                      rank: json.rank_id - 1,
                  };
                  this.setState(newState);
              }
              this.setState({isStatLoading: false});
          });
  };

  onTabChange = (key) => {
      if (key === 'info' || key === 'rank')
      {
          this.setState({showSearchInput: false});
      }
      else if (this.state.showSearchInput === false)
      {
          this.setState({showSearchInput: true});
      }
      if (key === 'strip') {
        this.setState({stripeShowCount: 20});
      }
      if (key === 'mark') {
        this.setState({markShowCount: 20});
      }
      if (key === 'badge') {
        this.setState({badgeShowCount: 20});
      }
      this.setState({activeTabKey: key});
  };

  onSearch = (e) => {
      if (this.searchTimer)
      {
          clearTimeout(this.searchTimer);
      }
      const val = e.target.value;
      this.searchTimer = setTimeout(() => {
          this.setState({searchString: val});
      }, 700);
  };

  onHideAchievement = (type) => {
    this.setState(prevState => ({
      hideAchievements: {
        ...prevState.hideAchievements,
        [type]: !prevState.hideAchievements[type]
      }
    }));
  };

  searchAchievements = (data, val) => {
    return Object.keys(data).reduce((acc, value) => {
      if (data[value].name.toLowerCase().includes(val) || data[value].description.toLowerCase().includes(val))
      {
        return {
          ...acc,
          [value]: data[value]
        };
      }
      else
      {
        return acc;
      }
    }, {});
  };

  get server() {
    return `Сервер: ${this.state.servers[this.state.server]}`;
  }

  get currentRank() {
    return this.state.ranks[this.state.rank];
  }

  get currentMark() {
    return this.state.achievements.mark[this.state.mark];
  }

  get currentStrip() {
    return this.state.achievements.stripe[this.state.strip];
  }

  get currentBadge() {
    return this.state.achievements.badge[this.state.badge];
  }

  get stats() {
      const {stats} = this.state;
      return `${stats.playTime} Ч.\n${stats.PVEClass}\n${stats.PVEWins}\n${stats.PVPCLass}\n${stats.PVPWins}\n${stats.KD}`;
  }

  get mark() {
      const {searchString} = this.state;
      const val = searchString.toLowerCase();
      if (!this.state.achievements.mark)
      {
          return [];
      }
      if (val.length)
      {
          return this.searchAchievements(this.state.achievements.mark, val);
      }
      else
      {
          return this.state.achievements.mark;
      }
  }

  get stripe() {
      const {searchString} = this.state;
      const val = searchString.toLowerCase();
      if (!this.state.achievements.stripe)
      {
          return [];
      }
      if (val.length)
      {
          return this.searchAchievements(this.state.achievements.stripe, val);
      }
      else
      {
          return this.state.achievements.stripe;
      }
  }

  get badge() {
      const {searchString} = this.state;
      const val = searchString.toLowerCase();
      if (!this.state.achievements.badge)
      {
          return [];
      }
      if (val.length)
      {
          return this.searchAchievements(this.state.achievements.badge, val);
      }
      else
      {
          return this.state.achievements.badge;
      }
  }

  render() {
    const {parameters} = this.state;
    return (
        <React.Fragment>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="/"><img src={logo} style={{width: 30, height: 30, marginRight: 5}}/>WFApps</Navbar.Brand>
                    {/*<Nav className="mr-auto">
                        <Nav.Link href="/uzerbar" className="disabled">Юзербары</Nav.Link>
                        <Nav.Link href="/" className="disabled">Аватарки</Nav.Link>
                    </Nav>*/}
                </Container>
            </Navbar>
            <Container>
                {this.state.currentPage === 0 ? (
                    <MainMenu changeCurLocation={() => {this.state.isLoaded && this.setState({currentPage: 1});}}/>
                ) : (
                    <React.Fragment>
                        <Row className="align-items-center my-3">
                            <Col md={7}>
                                {(Object.keys(this.state.achievements).length && Object.keys(this.state.ranks).length) ? (

                                        <UzerbarKanva
                                            width={parameters.stage.width}
                                            height={parameters.stage.height}
                                            substrate={substrate}
                                            rank={this.currentRank}
                                            strip={this.currentStrip}
                                            mark={this.currentMark}
                                            badge={this.currentBadge}
                                            nickname={this.state.nickname}
                                            clan={this.state.clan}
                                            server={this.server}
                                            statLabels={this.statLabels}
                                            stats={this.stats}
                                            setUzerbarRef={this.setUzerbarRef}
                                            hideParts={this.state.hideAchievements}
                                        />
                                    ) :
                                    (
                                        <img src={substrate}/>
                                    )
                                }
                            </Col>
                            {(Object.keys(this.state.achievements).length && Object.keys(this.state.ranks).length) ? (
                                <Col md={5} className="pt-3 pt-md-0">
                                    <Button variant="outline-success" block onClick={this.onUzerbarDownload}>Сохранить</Button>
                                </Col>
                            ) : ''
                            }
                        </Row>
                        {this.state.showSearchInput && (
                            <Form>
                                <Form.Group>
                                    <Form.Control type="text" placeholder="Начните вводить название достижения..." onChange={this.onSearch}/>
                                </Form.Group>
                            </Form>
                        )}
                        <Tabs justify defaultActiveKey="info" id="uzerbar-tabs"  onSelect={this.onTabChange}>
                            <Tab eventKey="info" title="Общее">
                                <TabContent>
                                    <InfoForm
                                        onNicknameChange={this.onNicknameChange}
                                        onClanChange={this.onClanChange}
                                        onStatLoad={this.onStatLoad}
                                        onServerChange={this.onServerChange}
                                        nickname={this.state.nickname}
                                        clan={this.state.clan}
                                        error={this.state.apiError}
                                        isStatLoading={this.state.isStatLoading}
                                        selectedServer={this.state.server}
                                        servers={this.state.servers}
                                    />
                                </TabContent>
                            </Tab>
                            <Tab eventKey="rank" title={`Звание (${Object.keys(this.state.ranks).length || 0})`}>
                                <TabContent>
                                    <Row>
                                        {
                                            Object.keys(this.state.ranks).length
                                              ?
                                              this.state.ranks.map((item, index) =>
                                                  <Rank
                                                      key={index}
                                                      id={index}
                                                      onSelectAchievement={this.onChangeRank}
                                                      type="rank"
                                                      active={this.state.rank === index}
                                                      {...item}
                                                  />
                                              )
                                              :
                                              `Звания не найдены :(`
                                        }
                                    </Row>
                                </TabContent>
                            </Tab>
                            <Tab eventKey="strip" title={`Нашивка (${Object.keys(this.state.achievements.stripe).length || 0})`}>
                                <TabContent>
                                    {
                                      Object.keys(this.stripe).length
                                        ?
                                        (
                                            <HideAchievement
                                              active={this.state.hideAchievements.strip}
                                              type="strip"
                                              onHideAchievement={this.onHideAchievement}
                                            />
                                        )
                                        :
                                        ``
                                    }
                                    {
                                        Object.keys(this.stripe).length
                                          ?
                                          Object.keys(this.stripe).slice(0, this.state.stripeShowCount).map((item, index) =>
                                              <Achievement
                                                  key={this.stripe[item].id}
                                                  onSelectAchievement={this.onChangeStrip}
                                                  type="strip"
                                                  active={this.state.strip === item}
                                                  {...this.stripe[item]}
                                                  id={item}
                                              />
                                          )
                                          :
                                          `Достижения не найдены :(`
                                    }
                                </TabContent>
                            </Tab>
                            <Tab eventKey="badge" title={`Жетон (${Object.keys(this.state.achievements.badge).length || 0})`}>
                                <TabContent>
                                    {
                                      Object.keys(this.badge).length
                                        ?
                                        (
                                          <HideAchievement
                                            active={this.state.hideAchievements.badge}
                                            type="badge"
                                            onHideAchievement={this.onHideAchievement}
                                          />
                                        )
                                        :
                                        ``
                                    }
                                    {
                                        Object.keys(this.badge).length
                                          ?
                                          Object.keys(this.badge).slice(0, this.state.badgeShowCount).map((item, index) =>
                                              <Achievement
                                                  key={this.badge[item].id}
                                                  onSelectAchievement={this.onChangeBadge}
                                                  type="badge"
                                                  active={this.state.badge === item}
                                                  {...this.badge[item]}
                                                  id={item}
                                              />
                                          )
                                          :
                                          `Достижения не найдены :(`
                                    }
                                </TabContent>
                            </Tab>
                            <Tab eventKey="mark" title={`Значок (${Object.keys(this.state.achievements.mark).length || 0})`}>
                                <TabContent>
                                    {
                                      Object.keys(this.mark).length
                                        ?
                                        (
                                          <HideAchievement
                                            active={this.state.hideAchievements.mark}
                                            type="mark"
                                            onHideAchievement={this.onHideAchievement}
                                          />
                                        )
                                        :
                                        ``
                                    }
                                    {
                                        Object.keys(this.state.achievements).length
                                          ?
                                          Object.keys(this.mark).slice(0, this.state.markShowCount).map((item, index) =>
                                              <Achievement
                                                  key={this.mark[item].id}
                                                  onSelectAchievement={this.onChangeMark}
                                                  type="mark"
                                                  active={this.state.mark === item}
                                                  {...this.mark[item]}
                                                  id={item}
                                              />
                                          )
                                          :
                                          `Достижения не найдены :(`
                                    }
                                </TabContent>
                            </Tab>
                        </Tabs>
                    </React.Fragment>
                )}
                <div className="w-100 text-center">
                    <span className="h4">Не забудьте поделиться с друзьями нашим сервисом!</span>
                    <div
                        className="uSocial-Share my-2"
                        data-pid="c5a6de616ed0f34ef62bc1367198a588"
                        data-type="share"
                        data-options="round-rect,style1,default,absolute,horizontal,size32,eachCounter1,counter0,nomobile"
                        data-social="vk,fb,twi,ok,telegram,email"
                    ></div>
                </div>
            </Container>
            {
                this.state.showUpBtn && (
                    <Button variant="info" className="up-btn" onClick={this.smoothJumpUp}>
                        <i className="material-icons">
                            keyboard_arrow_up
                        </i>
                    </Button>
                )
            }
        </React.Fragment>
    );
  }
}

export default App;
