import React from 'react';

const MainMenu = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="20 0 408 285.75"
    >
        <g
            fill="none"
            stroke="#fff"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="0.553"
        >
            <path strokeWidth="0.128" d="M204.52 186.283l-65.46 37.16" />
            <path strokeWidth="0.183" d="M192.789 127.416l-94.536-44.3" />
            <path
                strokeWidth="0.142"
                d="M292.898 130.078c66.753-33.183 66.978-33.001 66.978-33.001"
            />
        </g>
        <g transform="translate(0 -11.25) translate(36.286 -23.813)" className="menu-link" onClick={(e) => {window.location = "https://vk.com/ava.wareteru";}}>
            <circle
                cx="76.706"
                cy="264.66"
                r="26.994"
                fill="#4a76a8"
                fillOpacity="0.686"
                stroke="#231f20"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.529"
            />
            <path
                fill="#fff"
                fillRule="evenodd"
                strokeWidth="1.121"
                d="M93.212 255.596c.25-.832 0-1.443-1.187-1.443H88.1c-.998 0-1.458.528-1.708 1.11 0 0-1.995 4.865-4.823 8.025-.915.915-1.33 1.206-1.83 1.206-.249 0-.623-.291-.623-1.123v-7.775c0-.998-.277-1.443-1.108-1.443H71.84c-.624 0-.999.463-.999.902 0 .946 1.414 1.165 1.56 3.826v5.78c0 1.267-.23 1.496-.728 1.496-1.33 0-4.567-4.886-6.487-10.478-.376-1.087-.753-1.526-1.756-1.526h-3.925c-1.121 0-1.346.528-1.346 1.11 0 1.04 1.331 6.196 6.196 13.015 3.243 4.657 7.813 7.181 11.97 7.181 2.495 0 2.804-.56 2.804-1.526v-3.52c0-1.121.236-1.345 1.026-1.345.582 0 1.58.291 3.909 2.536 2.661 2.661 3.1 3.855 4.597 3.855h3.924c1.122 0 1.683-.56 1.36-1.667-.355-1.103-1.625-2.703-3.311-4.6-.915-1.08-2.287-2.245-2.703-2.827-.582-.748-.416-1.08 0-1.746 0 0 4.782-6.736 5.28-9.023z"
            />
        </g>
        <g transform="translate(0 -11.25)">
            <circle
                cx="242.681"
                cy="160.158"
                r="53.988"
                fill="#39204d"
                fillOpacity="0.686"
                stroke="#231f20"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.529"
            />
            <text
                x="207.936"
                y="153.744"
                fill="#000"
                fillOpacity="1"
                stroke="none"
                strokeWidth="0.265"
                fontFamily="sans-serif"
                style={{ lineHeight: "1.25" }}
                fontSize="10.583"
                fontStyle="normal"
                fontWeight="normal"
                letterSpacing="0"
                wordSpacing="0"
                xmlSpace="preserve"
            >
                <tspan
                    x="207.936"
                    y="153.744"
                    fill="#f1ffff"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    fontFamily="warface_regular"
                    fontSize="19.756"
                    fontStretch="normal"
                    style={{ InkscapeFontSpecification: "warface_regular" }}
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                >
                    WFApps
                </tspan>
            </text>
            <text
                x="243.408"
                y="167.909"
                fill="#c90000"
                fillOpacity="1"
                stroke="none"
                strokeWidth="0.265"
                fontFamily="sans-serif"
                style={{ lineHeight: "1.25" }}
                fontSize="10.583"
                fontStyle="normal"
                fontWeight="normal"
                letterSpacing="0"
                wordSpacing="0"
                xmlSpace="preserve"
            >
                <tspan
                    x="243.408"
                    y="167.909"
                    fill="#c90000"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    textAnchor="middle"
                    style={{ WebkitTextAlign: "center", textAlign: "center" }}
                >
                    Выбери свое
                </tspan>
                <tspan
                    x="243.408"
                    y="181.138"
                    fill="#c90000"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    textAnchor="middle"
                    style={{ WebkitTextAlign: "center", textAlign: "center" }}
                >
                    приложение
                </tspan>
            </text>
        </g>
        <g transform="translate(0 -11.25)" className="menu-link" onClick={e => {props.changeCurLocation();}}>
            <circle
                cx="69.757"
                cy="66.346"
                r="40.358"
                fill="#1e81ce"
                fillOpacity="0.686"
                stroke="#231f20"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.529"
            />
            <text
                x="69.931"
                y="62.337"
                fill="#fff"
                fillOpacity="1"
                stroke="none"
                strokeWidth="0.265"
                fontFamily="sans-serif"
                style={{
                    lineHeight: "1.25",
                    WebkitTextAlign: "center",
                    textAlign: "center"
                }}
                fontSize="10.583"
                fontStyle="normal"
                fontWeight="normal"
                letterSpacing="0"
                textAnchor="middle"
                wordSpacing="0"
                xmlSpace="preserve"
            >
                <tspan
                    x="69.931"
                    y="62.337"
                    fill="#fff"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    fontFamily="warface_regular"
                    fontSize="12.7"
                    style={{
                        WebkitTextAlign: "center",
                        textAlign: "center",
                        InkscapeFontSpecification: "warface_regular"
                    }}
                    fontStretch="normal"
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    textAnchor="middle"
                >
                    ГЕНЕРАТОР
                </tspan>
                <tspan
                    x="69.931"
                    y="75.567"
                    fill="#fff"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    fontFamily="warface_regular"
                    fontSize="12.7"
                    style={{
                        WebkitTextAlign: "center",
                        textAlign: "center",
                        InkscapeFontSpecification: "warface_regular"
                    }}
                    fontStretch="normal"
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    textAnchor="middle"
                >
                    ЮЗЕРБАРОВ
                </tspan>
            </text>
        </g>
        <g transform="translate(0 -11.25) translate(-34.774 21.545)" className="menu-link">
            <circle
                cx="415.871"
                cy="62.872"
                r="31.538"
                fill="#5b9d2c"
                fillOpacity="0.686"
                stroke="#231f20"
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.529"
            />
            <text
                x="416.552"
                y="58.863"
                fill="#fff"
                fillOpacity="1"
                stroke="none"
                strokeWidth="0.265"
                fontFamily="sans-serif"
                style={{ lineHeight: "1.25" }}
                fontSize="10.583"
                fontStyle="normal"
                fontWeight="normal"
                letterSpacing="0"
                wordSpacing="0"
                xmlSpace="preserve"
            >
                <tspan
                    x="416.552"
                    y="58.863"
                    fill="#fff"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    fontFamily="warface_regular"
                    fontSize="9.878"
                    fontStretch="normal"
                    style={{
                        InkscapeFontSpecification: "warface_regular",
                        WebkitTextAlign: "center",
                        textAlign: "center"
                    }}
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    textAnchor="middle"
                >
                    ГЕНЕРАТОР
                </tspan>
                <tspan
                    x="416.552"
                    y="72.092"
                    fill="#fff"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    fontFamily="warface_regular"
                    fontSize="9.878"
                    fontStretch="normal"
                    style={{
                        InkscapeFontSpecification: "warface_regular",
                        WebkitTextAlign: "center",
                        textAlign: "center"
                    }}
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    textAnchor="middle"
                >
                    АВАТАРОВ
                </tspan>
                <tspan
                    x="416.552"
                    y="82.092"
                    fill="#fff"
                    fillOpacity="1"
                    strokeWidth="0.265"
                    fontFamily="warface_regular"
                    fontSize="5"
                    fontStretch="normal"
                    style={{
                        InkscapeFontSpecification: "warface_regular",
                        WebkitTextAlign: "center",
                        textAlign: "center"
                    }}
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    textAnchor="middle"
                >
                    недоступен :(
                </tspan>
            </text>
        </g>
    </svg>
);

export default MainMenu;