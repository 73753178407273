import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export class LazyImage extends React.Component {
    render() {
        const { alt, src, srcset, sizes, width, height, placeholder } = this.props;
        return (
            <LazyLoadImage
                alt={alt}
                height={height}
                src={src}
                width={width}
                placeholder={placeholder}
            />
        );
    }
}

export default LazyImage;