import React, {Component} from 'react';
import {Card} from 'react-bootstrap';
import './style.scss';
import LazyImage from '../LazyImage';

class Rank extends Component {
    onClick = () => {
        this.props.onSelectAchievement(this.props.id);
    };

    render() {
        return (
            <React.Fragment>
                <Card onClick={this.onClick} className={'col-6 col-sm-4 col-md-3 ' + (this.props.active ? 'active' : '')}>
                    <Card.Body className="achievement row justify-content-center">
                        <div className="col-md-8">
                            <div className="achievement__title">{this.props.name}</div>
                        </div>
                        <div className={`col-md-4 text-center text-md-right achievement__img img-${this.props.type}`}>
                            <LazyImage src={this.props.image} alt={this.props.name} placeholder={<img src={`/images/placeholders/rank.png`}/>}/>
                        </div>
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}
export default Rank;