import React from 'react';
import {Form, Row, Alert, Button, Col} from 'react-bootstrap';
import cloudIcon from './cloud-icon.svg';
import './style.scss';

function InfoForm(props) {
    return (
        <Form>
            {
                (props.error && props.error.message) && (
                    <Alert variant="danger">
                        <Alert.Heading>Во время получения данных о персонаже произошла ошибка</Alert.Heading>
                        {props.error.message}
                    </Alert>
                )
            }
            <Row>
                <Form.Group className="col-md-6">
                    <Form.Label>Никнейм</Form.Label>
                    <Form.Control type="text" onChange={props.onNicknameChange} placeholder="Введите никнейм" value={props.nickname}/>
                    <Form.Label>Клан</Form.Label>
                    <Form.Control type="text" onChange={props.onClanChange} placeholder="Введите название клана" value={props.clan}/>
                    <Form.Label>Сервер</Form.Label>
                    <Form.Control as="select" value={props.selectedServer} onChange={props.onServerChange}>
                        {
                            Object.keys(props.servers).map(item => (<option value={item} key={item}>{props.servers[item]}</option>))
                        }
                        <option disabled>EU</option>
                        <option disabled>US</option>
                    </Form.Control>
                    <Button variant="outline-info" className="mt-3" block onClick={props.onStatLoad} disabled={props.isStatLoading}>Загрузить статистику игрока</Button>
                </Form.Group>
                <Form.Group className="col-md-6">
                    <div className="row justify-content-center">
                        <Col sm={6} xs={6}>
                            <img src={cloudIcon} className="wf-api-icon"/>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>Вы можете взять статистику персонажа прямо из игры! Укажите только никнейм и нажмите на кнопку ниже</Form.Label>
                        </Col>
                    </div>
                </Form.Group>
            </Row>
        </Form>
    );
}

export default InfoForm;