import React, {Component} from 'react';
import {Card} from 'react-bootstrap';
import './style.scss';
import LazyImage from '../LazyImage';

class HideAchievement extends Component {
    onClick = () => {
        this.props.onHideAchievement(this.props.type);
        console.log('this.props.type', this.props.type);
    };

    render() {
        return (
            <React.Fragment>
                <Card onClick={this.onClick} className={this.props.active ? 'active' : ''}>
                    <Card.Body className="achievement-hide row justify-content-center">
                        <div className="col-md-8">
                            <div className="achievement__title">{!this.props.active ? `Скрыть` : `Показать`}</div>
                        </div>
                        <div className={`col-md-4 text-center text-md-right achievement__img img-hide`}>
                            <LazyImage
                              src='/images/placeholders/hide.png'
                              alt={!this.props.active ? `Скрыть` : `Показать`}
                              placeholder={<img src={`/images/placeholders/hide.png`}/>}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}
export default HideAchievement;